const PATH = process.env.PUBLIC_URL;

export const images = {
    seven_rings: PATH + '/img/seven_rings.png',
    playstation5: PATH + '/img/playstation5.png',
    gold: PATH + '/img/medal/gold.png',
    sliver: PATH + '/img/medal/sliver.png',
    bronze: PATH + '/img/medal/bronze.png',
    kiring: PATH + '/img/goods/kiring.png',
    airStick: PATH + '/img/goods/airStick.png',
    hairpin: PATH + '/img/goods/hairpin.png',
    tatoo: PATH + '/img/goods/tatoo.png',
    towel: PATH + '/img/goods/towel.png',
    poster: PATH + '/img/cinema/poster.png',
    popcon: PATH + '/img/cinema/popcon.png',
    sungkyul: PATH + 'img/sungkyul.png',
    likelion_logo: PATH + '/img/aboutLogo/likelion.png',
    renew_logo: PATH + '/img/aboutLogo/renew.png',
    boothAll: PATH + 'img/boothLocation/boothAll.png',
    locateMenu: PATH + 'img/boothLocation/locateMenu.png',
    사복: PATH + '/img/departmentLogo/사복.png',
    경영: PATH + '/img/departmentLogo/경영.png',
    글물: PATH + '/img/departmentLogo/글물.png',
    유교: PATH + '/img/departmentLogo/유교.png',
    연기: PATH + '/img/departmentLogo/연영.png',
    국제: PATH + '/img/departmentLogo/국제.png',
    문선: PATH + '/img/departmentLogo/문선.png',
    체교: PATH + '/img/departmentLogo/체교.png',
    중문: PATH + '/img/departmentLogo/중문.png',
    신학: PATH + '/img/departmentLogo/신학.png',
    뷰티: PATH + '/img/departmentLogo/뷰티.png',
};
